import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logOut } from "../Actions/User";
import Button from "@mui/material/Button";
import { jwtDecode } from "jwt-decode";

import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel } from "@mui/material";
import { setBranch } from "../Actions/branchActions";

const user_email = localStorage.getItem("email");
const user_role: any = localStorage.getItem("access_token");

let roleobj: any = null;
if (user_role) {
  roleobj = jwtDecode(user_role);
}

const Header = () => {
  const Navigate = useNavigate();
  const ref = useRef<any>(null);
  const dispatch = useDispatch();
  // const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const { isAuthenticated } = useSelector((state: any) => state.user);

  const branch = useSelector((state: any) => state.branch);

  const handleBranchChange = (event: any) => {
    dispatch(setBranch(event.target.value));
    window.location.reload();
    localStorage.setItem(
      "cur_branch",
      event.target.options[event.target.value - 1].innerHTML
    );
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const handleLogOut = async () => {
    dispatch(logOut());
    window.location.href = "/";
  };
  const handleLogoClick = async () => {
    window.location.href = "/";
  };

  return (
    <nav className="navbar">
      {/* <div className='image_container'> */}
      <img
        onClick={handleLogoClick}
        src="https://value360-conf-booking-system.s3.ap-south-1.amazonaws.com/public-images/conf-room/logo/value-360-group.webp"
        alt="value360-logo"
        className="main_logo"
      />
      <div className="logo-container">
        <img
          onClick={handleLogoClick}
          src="https://value360-conf-booking-system.s3.ap-south-1.amazonaws.com/public-images/conf-room/logo/value_360_logo.png"
          alt="Value360"
          className="logo"
        />
        <img
          onClick={handleLogoClick}
          src="https://value360-conf-booking-system.s3.ap-south-1.amazonaws.com/public-images/conf-room/logo/Popkorn_logo.png"
          alt="Popkorn"
          className="logo"
        />
        <img
          onClick={handleLogoClick}
          src="https://value360-conf-booking-system.s3.ap-south-1.amazonaws.com/public-images/conf-room/logo/clan_connect_logo.png"
          alt="ClanConnect"
          className="logo"
        />
      </div>
      <div className="avatar-container">
        {isAuthenticated && (
          <>
            <FormControl
              sx={{ m: 2, display: branch.visible ? "block" : "none" }}
            >
              <select
                value={branch.branch}
                onChange={handleBranchChange}
                style={{ borderRadius: 5, padding: 5, fontWeight: "bold" }}
              >
                <option value={3}>Value 360 - Mumbai</option>
                <option value={2}>Value 360 - Bengaluru</option>
                <option value={1}>Value 360 - Delhi</option>
                <option value={5}>Popkorn - Delhi</option>
              </select>
            </FormControl>
            <div className="details">
              <div onClick={handleToggle}>
                <p style={{ color: "rgb(255, 166, 0)", textAlign: "end" }}>
                  {user_email}
                </p>
                <p style={{ color: "rgb(255, 166, 0)", textAlign: "end" }}>
                  {roleobj?.role}
                </p>
              </div>
              <img
                style={{ marginLeft: 10 }}
                src="https://img.freepik.com/free-icon/user_318-159711.jpg"
                alt="User Avatar"
                className="avatar"
              />
              {isExpanded && (
                <div className="logout-container" ref={ref}>
                  <p className="logout-button" onClick={handleLogOut}>
                    Log out
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Header;
