import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Stack, Typography, TableSortLabel, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";

import axios from "../../utils/axios";
import { jwtDecode } from 'jwt-decode';
import * as XLSX from 'xlsx';
import { FaFile } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { loading } from '../../Actions/branchActions';
import InsertExpenseType from "../InsertModal/InsertExpenseType";
import ExpenseTypeMaster from "../MasterForms/ExpenseTypeMaster";


const user_role: any = localStorage.getItem('access_token');

let roleobj: any = null;
if (user_role) {
  roleobj = jwtDecode(user_role);
}


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

interface Column {
  id:
  | "expense_type"
  | "expense_category"
  | "frequency"
  | "billable"
  | "non_billable"
  | "team_lead"
  | "client"
  | "description"
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "expense_type", label: "Type", minWidth: 150 },
  { id: "expense_category", label: "Category", minWidth: 200 },
  { id: "frequency", label: "Frequency", minWidth: 200 },
  { id: "billable", label: "Billable", minWidth: 200 },
  { id: "non_billable", label: "Non Billable", minWidth: 100 },
  { id: "team_lead", label: "Team", minWidth: 160 },
  { id: "client", label: "Client", minWidth: 80 },
  { id: "description", label: "description", minWidth: 150 },
];

const ExpenseTypes = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setrows] = useState<any>([]);
  const [excelData, setExcelData] = useState<any>([]);
  const [doRefresh, setDoRefresh] = useState(false);

  const updateRefresh = (refreshState: boolean) => {
    setDoRefresh(refreshState);
  };

  const [sortModel, setSortModel] = useState<any>([
    { field: 'expense_category', sort: 'asc' },
  ]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'expense_types.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const filteredRows = searchTerm
    ? rows.filter((row: any) =>
      Object.values(row).some(
        (value: any) =>
          value &&
          value
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      )
    )
    : rows;

  useEffect(() => {
    dispatch(loading(true))
    const getAllTypes = async () => {
      const response: any = await axios.post(
        `/get-all-expense-types`, {}
      );
      if (response.data.fetched) {
        setrows(response.data.expenses);
        setExcelData(response.data.expenses);
      } else {
        setrows([]);
      }
      // setrows([]);
      dispatch(loading(false))
    };

    getAllTypes();
    updateRefresh(false);
  }, [doRefresh]);

  return (
    <React.Fragment>

      <Stack
        paddingX={"2%"}
        marginTop={"2px"}
        width="100%"
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >

        <Stack
          padding="10px"
          bgcolor="primary.main"
          color="primary.contrastText"
          direction="row"
          justifyContent={"space-between"}
          width={"100%"}
          marginY={"5px"}
          borderRadius={"10px"}
          className="custom-stack-layout"
        >
          <Stack direction={"row"} spacing={4}>
            {
              (roleobj?.role === "Finance" || roleobj?.role === "Director" || roleobj?.role === "Admin") &&
              <InsertExpenseType updateRefresh={updateRefresh} />
            }
            <Button variant="contained" style={{ backgroundColor: "white", color: "#2196F3", marginLeft: 10 }} onClick={exportToExcel}>Export to Excel<FaFile style={{ marginLeft: '10px' }
            } /></Button>
            <Search style={{ marginLeft: 10 }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearchChange}
                style={{ width: '40vw' }}
              />
            </Search>
          </Stack>
          <Typography fontSize={"25px"}>Expense Types</Typography>
        </Stack>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "80vh", overflowX: "scroll" }}>
            <Table className="table-custom-style" stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    key="info-cell"
                    align="center"
                    style={{ minWidth: 30, fontWeight: "bolder" }}
                  >
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "bolder" }}
                    >
                      <TableSortLabel
                        active={sortModel[0].field === column.id}
                        direction={sortModel[0].sort}
                        onClick={() => setSortModel([{ field: column.id, sort: sortModel[0].sort === 'desc' ? 'asc' : 'desc' }])}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .sort((a: any, b: any) => (a[sortModel[0].field] > b[sortModel[0].field] ? 1 : -1) * (sortModel[0].sort === 'desc' ? 1 : -1))
                  .map((row: any, index: number) => {
                    return (
                      <TableRow hover role="button" tabIndex={-1} key={index}>
                        <ExpenseTypeMaster
                          updateRefresh={updateRefresh}
                          id={row.id}
                          expense_type={row.expense_type}
                          expense_category={row.expense_category}
                          frequency={row.frequency}
                          is_billable={row.billable === "Yes" ? true : false}
                          non_billable={row.non_billable === "Yes" ? true : false}
                          team_lead={row.team_lead === "Yes" ? true : false}
                          is_client={row.client === "Yes" ? true : false}
                          description={row.description}
                        />
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  maxWidth: "200px",
                                }}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </div>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 20, 30]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Stack>
    </React.Fragment>
  );
};

export default ExpenseTypes;
